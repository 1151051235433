import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Container from 'components/Container';
import { Topbar, Sidebar, Footer } from './components';
import pages from '../navigation--docs';
import { makeStyles } from '@material-ui/core/styles';

import { graphql,useStaticQuery, navigate, Link } from 'gatsby';
import { useLocation } from '@reach/router';

import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Highlight, { defaultProps } from 'prism-react-renderer'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExternalLinkIcon from '@material-ui/icons/OpenInNewSharp';
import LangIcon from '@material-ui/icons/PublicSharp';
import TipIcon from '@material-ui/icons/EmojiObjectsSharp';

import HighlightTheme from 'prism-react-renderer/themes/vsDark';
import BlogArticle from 'views/BlogArticle';


const FONT_TITLE = '"Titillium Web", sans-serif';
const FONT_TOC = '"Titillium Web", sans-serif';
const FONT_TEXT = '"Titillium Web", sans-serif';
const FONT_CODE = '"Titillium Web", sans-serif';

const FONT_ZH_TITLE = 'simhei,stheiti';
const FONT_ZH_TOC = 'heiti';
const FONT_ZH_TEXT = 'arial,sans-serif';
const useStyles = makeStyles(theme => ({
  head: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: theme.TOOLBAR_HEIGHT,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    backgroundColor: '#222',
  },

  home: {
    width: theme.TAB_WIDTH,
    height: theme.TOOLBAR_HEIGHT,
    paddingLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  tools: {
    height: theme.TOOLBAR_HEIGHT,
    flexGrow: 1,
    paddingRight: theme.spacing(5),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'right',
  },

  logo: {
    width: '36px',
    cursor: 'pointer',
    transition: 'opacity 0.5s',
    '&:hover': {
      opacity: '60%',
    },
  },

  nav: {
    position: 'absolute',
    top: theme.TOOLBAR_HEIGHT,
    bottom: 0,
    width: '300px',
    padding: theme.spacing(2),
    overflowY: 'scroll',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '8px',
      backgroundColor: '#303030',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#383838',
    },
  },

  navTitle: {
    fontFamily: FONT_TITLE,
    fontSize: '1rem',
    fontWeight: 'lighter',
    paddingLeft: theme.spacing(2),
  },

  navItem: {
    height: '26px',
  },

  navItemText: {
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    '&:hover': {
      textDecorationLine: 'underline',
    },
  },

  navItemTextParent: {
    color: theme.palette.text.primary,
    cursor: 'pointer',
    '&:hover': {
      textDecorationLine: 'underline',
    },
    fontWeight: 'bold',
  },

  navItemTextCurrent: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecorationLine: 'underline',
    },
    fontWeight: 'bold',
  },

  navGroup: {
    paddingLeft: theme.spacing(2),
  },

  toc: {
    position: 'absolute',
    top: theme.TOOLBAR_HEIGHT,
    bottom: 0,
    width: '300px',
    right: 0,
    padding: theme.spacing(2),
  },

  tocTitle: {
    fontFamily: FONT_TITLE,
    fontSize: '1rem',
    fontWeight: 'lighter',
  },

  tocList: {
    listStyleType: 'none',
    paddingInlineStart: 0,
    marginInlineStart: 0,
    fontFamily: FONT_TOC,
    fontSize: '0.9rem',
    fontWeight: 'lighter',
  },

  tocListItem: {
    color: theme.palette.text.link,
    textDecorationLine: 'none',
    '&:hover': {
      textDecorationLine: 'underline',
    },
  },

  main: {
    position: 'absolute',
    top: theme.TOOLBAR_HEIGHT,
    bottom: 0,
    left: '300px',
    right: '300px',
    padding: theme.spacing(2),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '8px',
      backgroundColor: '#303030',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#383838',
    },
  },

  title: {
    fontFamily: FONT_TITLE,
    fontSize: '3rem',
  },

  title_zh: {
    fontFamily: FONT_ZH_TITLE,
    fontSize: '3rem',
    fontWeight: 'bolder',
  },

  h1: { fontFamily: FONT_TITLE, fontSize: '2.0rem' },
  h2: { fontFamily: FONT_TITLE, fontSize: '1.5rem', fontWeight: 'lighter' },
  h3: { fontFamily: FONT_TITLE, fontSize: '1.3rem', fontWeight: 'lighter' },
  h4: { fontFamily: FONT_TITLE, fontSize: '1.1rem', fontWeight: 'lighter' },

  h1_zh: { fontFamily: FONT_ZH_TITLE, fontSize: '2.0rem' },
  h2_zh: { fontFamily: FONT_ZH_TITLE, fontSize: '1.5rem', fontWeight: 'bolder' },
  h3_zh: { fontFamily: FONT_ZH_TITLE, fontSize: '1.3rem', fontWeight: 'bolder' },
  h4_zh: { fontFamily: FONT_ZH_TITLE, fontSize: '1.1rem', fontWeight: 'bolder' },

  p: {
    fontFamily: FONT_TEXT,
    fontSize: '1.0rem',
    lineHeight: '1.8rem',
  },

  tip: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
    margin: 0,
	color: '#181818',
    backgroundColor: '#ddeeff',
    borderLeftStyle: 'solid',
    borderLeftWidth: '3px',
    borderLeftColor: '#ffee58',
  },

  tipIcon: {
    flexGrow: 0,
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  tipText: {
    flexGrow: 1,
    fontFamily: FONT_TEXT,
    fontSize: '1.0rem',
    lineHeight: '1.8rem',
  },

  link: {
    color: '#3399ff',
    textDecorationLine: 'underline',
    textUnderlinePosition: 'under',
    '&:hover': {
      textDecorationLine: 'none',
    },
  },

  externalLinkIcon: {
    verticalAlign: 'sub',
  },

  codeBox: {
    fontFamily: FONT_CODE,
    fontSize: '100%',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: 0,
	borderRadius: 10,
    borderLeftStyle: 'solid',
    borderLeftWidth: '3px',
    borderLeftColor: '#ffee58',
  },

  codeSame: {
    opacity: '70%',
  },

  codeAdded: {
    backgroundColor: '#1e3e1e',
  },

  codeDeleted: {
    backgroundColor: '#3e1e1e',
    textDecorationLine: 'line-through',
  },

  inlineCode: {
    fontFamily: FONT_CODE,
    fontSize: '1.0rem',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingTop: '3px',
    paddingBottom: '3px',
    backgroundColor: '#ffee58',
    color: '#181818',
  },

  memberName: {
    fontFamily: FONT_CODE,
    fontSize: '1.0rem',
    fontWeight: 'bold',
    color: theme.palette.text.link,
    padding: theme.spacing(0.8),
    backgroundColor: theme.palette.text.codeBox,
    textDecorationLine: 'underline',
    textUnderlinePosition: 'under',
    '&:hover': {
      textDecorationLine: 'none',
    },
  },

  parameterName: {
    fontFamily: FONT_CODE,
    fontSize: '1.0rem',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    padding: theme.spacing(0.8),
    backgroundColor: theme.palette.text.codeBox,
  },

  description: {
    paddingInlineStart: theme.spacing(3),
    fontFamily: FONT_TEXT,
    fontSize: '1.0rem',
  },

  selected: {
    color: theme.palette.primary.main,
  },
}));

export const query = graphql`
  query(
    $slug: String
  ) {
    mdx(slug: {eq: $slug} ) {
      frontmatter {
				title
				image
				description
				author {
					name
					avatar
				}
				date
      }
      body
      headings {
        depth
        value
      }
    }
    allMdx (filter: {slug: {regex: "/blog/"}}) {
      nodes {
		    slug
        frontmatter {
		      title
		      image
		      description
					author {
		        name
		        avatar
		      }
		      date
        }
      }
    }
  }
`;

const DocContext = React.createContext();

const makeStyledTag = (tag, style) => {
  style = style || tag;
  return ({ children, ...props }) => {
    const classes = useStyles();
    const lang = 'en';
    const Tag = tag;
    const className = classes[`${style}_${lang}`] || classes[style]
    return (
      <Tag {...props} className={className}>
        {children}
      </Tag>
    );
  };
}


const SourceCode = ({ children, className }) => {
  const classes = useStyles();
  const language = className === 'language-js' ? 'javascript' : undefined;
  const lines = children.split('\n');

  let isDiff = true;
  const colors = lines.map(
    line => {
      if (line.trim() === '') return;
      if (line.startsWith('  ')) return classes.codeSame;
      if (line.startsWith('+ ')) return classes.codeAdded;
      if (line.startsWith('- ')) return classes.codeDeleted;
      isDiff = false;
    }
  );

  if (isDiff) {
    children = lines.map(l => l.substring(2)).join('\n');
  }

  return (
    <Highlight {...defaultProps} code={children} language={language} theme={HighlightTheme}>
      {({ style, tokens, getLineProps, getTokenProps }) => (
        <pre className={classes.codeBox} style={{ ...style }}>
          {tokens.map((line, i) => {
            const props = getLineProps({ line, key: i });
            if (isDiff) props.className = colors[i];
            return (
              <div key={i} {...props}>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </div>
            );
          })}
        </pre>
      )}
    </Highlight>
  );
}

const DocLink = ({ children, href }) => {
  const classes = useStyles();
  // const { lang } = React.useContext(DocContext);
  if (href.startsWith('#') || href.startsWith('/')) {
    return (
      <Link to={href} className={classes.link}>
        {children}
      </Link>
    );
  } else {
    return (
      <a href={href} target="_blank" className={classes.link}>
        {children}
        {<ExternalLinkIcon fontSize="small" className={classes.externalLinkIcon}/>}
      </a>
    );
  }
}

const Tip = ({ children }) => {
  const classes = useStyles();
  return (
    <blockquote className={classes.tip}>
      <div className={classes.tipIcon}><TipIcon fontSize="large"/></div>
      <div className={classes.tipText}>{children}</div>
    </blockquote>
  )
}

const Classes = () => {
  const classes = useStyles();
  const loc = useLocation();
  let { jsdoc, lang, path } = React.useContext(DocContext);
  if (path.endsWith('/')) path = path.substring(0, path.length - 1);
  const members = jsdoc.members.static.filter(m => m.kind === 'class');
  return (
    members.map(
      m => (
        <React.Fragment>
          <Link
            className={classes.memberName}
            to={`${loc.origin}${path}/${m.name}`}
          >
            {m.name}
          </Link>
          <p className={classes.description}>
            {m.description?.internal?.content || '[No description]'}
          </p>
        </React.Fragment>
      )
    )
  );
}

const Constructor = () => {
  const classes = useStyles();
  const loc = useLocation();
  let { jsdoc, lang, path } = React.useContext(DocContext);
  if (path.endsWith('/')) path = path.substring(0, path.length - 1);
  return (
    <React.Fragment>
      <Link
        className={classes.memberName}
        to={`${loc.origin}${path}/constructor`}
      >
        new {jsdoc.memberof ? jsdoc.memberof + '.' : ''}{jsdoc.name}()
      </Link>
      <p className={classes.description}>
        Create an instance of {jsdoc.name}.
      </p>
    </React.Fragment>
  );
}

const Properties = () => {
  const classes = useStyles();
  const loc = useLocation();
  let { jsdoc, lang, path } = React.useContext(DocContext);
  if (path.endsWith('/')) path = path.substring(0, path.length - 1);
  const members = jsdoc.members.instance.filter(m => m.kind === 'member');
  return (
    members.map(
      m => (
        <React.Fragment>
          <Link
            className={classes.memberName}
            to={`${loc.origin}${path}/${m.name}`}
          >
            {m.name}
          </Link>
          <p className={classes.description}>
            {m.description?.internal?.content || '[No description]'}
          </p>
        </React.Fragment>
      )
    )
  );
}

const StaticProperties = () => {
  const classes = useStyles();
  const loc = useLocation();
  let { jsdoc, lang, path } = React.useContext(DocContext);
  if (path.endsWith('/')) path = path.substring(0, path.length - 1);
  const members = jsdoc.members.static.filter(m => m.kind === 'member');
  return (
    members.map(
      m => (
        <React.Fragment>
          <Link
            className={classes.memberName}
            to={`${loc.origin}${path}/${m.name}`}
          >
            {m.name}
          </Link>
          <p className={classes.description}>
            {m.description?.internal?.content || '[No description]'}
          </p>
        </React.Fragment>
      )
    )
  );
}

const Methods = () => {
  const classes = useStyles();
  const loc = useLocation();
  let { jsdoc, lang, path } = React.useContext(DocContext);
  if (path.endsWith('/')) path = path.substring(0, path.length - 1);
  const members = jsdoc.members.instance.filter(m => m.kind === 'function');
  return (
    members.map(
      m => (
        <React.Fragment>
          <Link
            className={classes.memberName}
            to={`${loc.origin}${path}/${m.name}`}
          >
            {m.name}()
          </Link>
          <p className={classes.description}>
            {m.description?.internal?.content || '[No description]'}
          </p>
        </React.Fragment>
      )
    )
  );
}

const StaticMethods = () => {
  const classes = useStyles();
  const loc = useLocation();
  let { jsdoc, lang, path } = React.useContext(DocContext);
  if (path.endsWith('/')) path = path.substring(0, path.length - 1);
  const members = jsdoc.members.static.filter(m => m.kind === 'function');
  return (
    members.map(
      m => (
        <React.Fragment>
          <Link
            className={classes.memberName}
            to={`${loc.origin}${path}/${m.name}`}
          >
            {m.name}()
          </Link>
          <p className={classes.description}>
            {m.description?.internal?.content || '[No description]'}
          </p>
        </React.Fragment>
      )
    )
  );
}

const Parameters = () => {
  const classes = useStyles();
  const { jsdoc } = React.useContext(DocContext);
  const params = jsdoc.params;
  return (
    params.map(
      p => (
        <React.Fragment>
          <h6>
            <span className={classes.parameterName}>{p.name}</span>
          </h6>
          <p className={classes.description}>
            {p.description?.internal?.content || '[No description]'}
          </p>
        </React.Fragment>
      )
    )
  );
}

const ReturnValue = () => {
  const classes = useStyles();
  const { jsdoc } = React.useContext(DocContext);
  return (
    <p className={classes.description}>
      {jsdoc.returns[0].description.internal.content || '[No description]'}
    </p>
  );
}

const components = {
  h1: makeStyledTag('h1'),
  h2: makeStyledTag('h2'),
  h3: makeStyledTag('h3'),
  h4: makeStyledTag('h4'),
  li: makeStyledTag('li', 'p'),
  p: makeStyledTag('p'),
  a: DocLink,
  blockquote: Tip,
  code: SourceCode,
  inlineCode: makeStyledTag('span', 'inlineCode'),

  Classes,
  Constructor,
  Properties,
  Methods,
  StaticProperties,
  StaticMethods,
  Parameters,
  ReturnValue,
};

const BlogMdx = ({ data,children }) => {
  // const data = useStaticQuery(query);
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Box>
      {data?<BlogArticle blogs={data.allMdx.nodes} frontmatter={data.mdx.frontmatter}>
		  <MDXProvider components={components}>
			  <MDXRenderer headings={data.mdx.headings}>
				{data.mdx.body}
			  </MDXRenderer>
		  </MDXProvider>
      </BlogArticle>:null}
	  
    </Box>
  );
};

// {members:{instance:[]}}
BlogMdx.propTypes = {
  children: PropTypes.node,
};

export default BlogMdx;
